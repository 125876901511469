<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import HomeFrame from "@/frame/HomeFrame.vue"
export default {
  name: 'app',
  components: {
    HomeFrame,
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
html {
  height: 100%;
}
body {
  height: 100vh;
  font-size: 16px;
  //overflow-x: hidden;
  //overflow:hidden;
}
.el-table__header {
  background-color: #f0f0f0;
}


/* 更改单元格字体颜色 */
.el-table__cell {
  color: #333;
}
</style>
