<template>
  <div class="el_bg">
    <el-container>
      <el-header
      height="120">
        <el-menu
            router
            mode="horizontal"
            class = "el_menu"
        >
          <el-menu-item index="add" class="el-menu_item">
            <i class="el-icon-circle-plus-outline"></i>
            <span slot="title">二楼当月新增</span>
          </el-menu-item>
          <el-menu-item index="home">
            <i class="el-icon-s-tools"></i>
            <span slot="title">二楼电费查询</span>
          </el-menu-item>
          <el-menu-item index="history">
            <i class="el-icon-setting"></i>
            <span slot="title">二楼历史操作记录</span>
          </el-menu-item>
          <el-menu-item index="addThree">
            <i class="el-icon-circle-plus-outline"></i>
            <span slot="title">三楼当月新增</span>
          </el-menu-item>
          <el-menu-item index="homeThree">
            <i class="el-icon-s-tools"></i>
            <span slot="title">三楼电费查询</span>
          </el-menu-item>
          <el-menu-item index="historyThree">
            <i class="el-icon-setting"></i>
            <span slot="title">三楼历史操作记录</span>
          </el-menu-item>
        </el-menu>
      </el-header>
      <el-container>
        <el-main>
         <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  name:"HomeFrame",
  data(){
    return{

    }
  }
};
</script>

<style scoped>
.el_bg{
  margin-left: 20px;
  margin-right: 20px;
  height: 100vh;
  //background-color: #eef1f6;
}
.el-menu{
  border: none;
}
.el-container{
  height: 100%;
  //border: 1px solid #eee
}
.el-header {
  //background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
  text-align: right;
  font-size: 12px;
  border: 1px solid #eee
}
.el-main{
  border: 1px solid #eee;
  padding:0px;
  overflow: unset;
  padding-top: 20px;
}

.el-aside {
  //color: #333;
  border: 1px solid #eee
}
</style>