import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'add',
    name: 'frame',
    component: () => import('../frame/HomeFrame.vue'),
    children:[
      {
        path: '/add',
        name: 'add',
        component: () => import('../views/AddView.vue')
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/history',
        name: 'history',
        component: () => import('../views/HistoryView.vue')
      },
      {
        path: '/addThree',
        name: 'addThree',
        component: () => import('../views/AddThreeView.vue')
      },
      {
        path: '/homeThree',
        name: 'homeThree',
        component: () => import('../views/HomeThreeView.vue')
      },
      {
        path: '/historyThree',
        name: 'historyThree',
        component: () => import('../views/HistoryThreeView.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
